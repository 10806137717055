import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../../../redux/store"

const mapState = (state: RootState) => ({
  error: state.auth.flows.signinLinkValidation.process.error,
  lang: state.lang.lang,
})

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
