import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../../redux/actions"
import { RootState } from "../../../../../redux/store"

const mapState = (state: RootState, props: { lang: string }) => ({
  current: state.auth.flows.signinLinkValidation.steps.current,
  loading: state.auth.flows.signinLinkValidation.process.fetching,
  focus: state.auth.flows.signinLinkValidation.form.email.focus,
  success: state.auth.flows.signinLinkValidation.process.succeed,
  error: !!state.auth.flows.signinLinkValidation.process.error,
  lang: props.lang,
})

const mapDispatch = (dispatch: any) => ({
  onMount() {
    dispatch(actions.auth.flows.signinLinkValidation.submit.execute())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
