import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState, props) => {
  const code = state.auth.flows.signinLinkValidation.form.email.state.code

  return {
    value: state.auth.flows.signinLinkValidation.form.email.value,
    state: code,
    error: code !== "GOOD" && code !== "NOTHING",
    isFocus: state.auth.flows.signinLinkValidation.form.email.focus,
  }
}

const mapDispatch = (dispatch: any) => ({
  onChange: (email: string) => {
    dispatch(actions.auth.flows.signinLinkValidation.form.email.update({ email }))
  },
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter")
      dispatch(actions.auth.flows.signinLinkValidation.form.email.next())
  },
  onFocus: () => {
    dispatch(actions.auth.flows.signinLinkValidation.form.email.focus.update({ focus: true }))
  },
  onBlur: () => {
    dispatch(
      actions.auth.flows.signinLinkValidation.form.email.focus.update({ focus: false })
    )
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
