import React from "react"
import { PageProps } from "gatsby"

import { useIntl } from "react-intl"

import { Seo } from "../components/Seo/Seo"
import { DarkMode } from "../components/DarkMode/DarkMode"
import { SigninLinkValidation } from "../authentication/auth/signin-link-validation"

type Props = PageProps<
  null,
  {
    langKey: string
    level: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const SigninLinkValidationPage: React.FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <DarkMode>
      <Seo
        title={intl.formatMessage({ id: "seo.signin-link-validation.title" })}
        description={intl.formatMessage({
          id: "seo.signin-link-validation.description",
        })}
        index={false}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
      />

      <SigninLinkValidation lang={props.pageContext.langKey} />
    </DarkMode>
  )
}

export default SigninLinkValidationPage
