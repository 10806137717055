import React, { useEffect } from "react"
import { Transition } from "@headlessui/react"
import { BackgroundShapes } from "../../../components/BackgroundShapes"
import { SigninLinkValidationBottomBarAction as BottomBarAction } from "../../../components/BottomBarAction"
import { SigninLinkValidationCancel as Cancel } from "../../../components/Cancel"
import { ShadowLoader } from "../../../components/ShadowLoader"
import { Error } from "../Error"
import { connector, ContainerProps } from "./container"
import { Success } from "../Success"
import { Loading } from "../Loading"
import { EmailCompletion } from "../EmailCompletion"
import { Wrapper as Confetti } from "../../../../../components/Confetti/Confetti"

export type Props = {
  lang: string
  onMount: Function
  current: number
  loading: boolean
  error: boolean
  focus: boolean
  success: boolean
}

const animations = {
  enter: "duration-1000",
  enterFrom: "opacity-0 -translate-y-10",
  enterTo: "opacity-1 translate-y-0",
  leave: "duration-300",
  leaveFrom: "opacity-1 translate-x-0",
  leaveTo: "opacity-0 -translate-x-52",
}

export const Wrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    props.onMount()
  }, [])

  return (
    <div className="w-full h-screen bg-slate-100">
      <ShadowLoader show={props.loading} />

      <Cancel lang={props.lang} />

      <div className="relative z-10 flex w-full">
        <Transition
          className="absolute w-full h-screen transition-all ease-in-out transform"
          show={props.current === 0 && !props.error && !props.success}
          {...animations}
        >
          <Loading />
        </Transition>

        <Transition
          className="absolute w-full h-screen transition-all ease-in-out transform"
          show={props.current === 1 && !props.error && !props.success}
          {...animations}
        >
          <EmailCompletion />
        </Transition>

        <Transition
          className="absolute w-full h-screen transition-all ease-in-out transform"
          show={props.error}
          {...animations}
        >
          <Error />
        </Transition>

        <Transition
          className="absolute w-full h-screen transition-all ease-in-out transform"
          show={props.success}
          {...animations}
        >
          <Success />
        </Transition>
      </div>

      <BottomBarAction />

      <Confetti isOpen={props.success} respawn={false} />

      <BackgroundShapes step={props.current} error={props.error} />
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const FullSteps = connector(Container)
