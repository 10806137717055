import React from "react"
import { connector, ContainerProps } from "./container"
import { Heading } from "../../../components/Heading/Heading"
import Illustration from "../../../../../assets/lottie/01-problem.json"
import { FormattedMessage } from "../../../../../components/FormattedMessage/FormattedMessage"
import { AuthenticateWithLinkReturnErrorType } from "../../../../../interfaces/IAuthRepository"
import { Link } from "gatsby"
import { getSigninUrl } from "../../../../../i18n/utils/getUrl"

export type Props = {
  error: AuthenticateWithLinkReturnErrorType | null
  lang: string
}

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <div
      data-testid="forgot-validation/errors"
      className="relative flex w-full h-full p-4 overflow-hidden shadow-inner"
    >
      <div className="w-full max-w-2xl mx-auto">
        <Heading
          title={<FormattedMessage id="login.error.title" />}
          description={
            <>
              {props.error === "EXPIRED_CODE" && (
                <FormattedMessage id="login.error.expired-code" />
              )}
              {props.error === "INVALID_EMAIL" && (
                <FormattedMessage id="login.error.invalid-email" />
              )}
              {props.error === "USER_DISABLED" && (
                <FormattedMessage id="login.error.user-disabled" />
              )}
              {props.error === "ERROR_SERVER" && (
                <FormattedMessage id="login.error.error-server" />
              )}
            </>
          }
          illustration={Illustration}
        ></Heading>

        <div className="mt-4 sm:mt-10">
          <div className="relative flex-shrink-0 max-w-lg mx-auto">
            <div className="group">
              <Link
                to={getSigninUrl(props.lang)}
                data-testid="form/error/retry"
                className={`transform relative z-10 group flex cursor-pointer items-center uppercase h-11  ${"bg-red-500 text-white transition-all duration-300 ease-in-out group-hover:translate-y-0.5"} rounded text-sm font-semibold justify-center`}
              >
                <>
                  <FormattedMessage id="login.error.retry" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 ml-3 transition-all duration-300 ease-in-out transform group-hover:translate-x-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </>
              </Link>

              <div className="absolute left-0 right-0 w-full transition-all duration-300 ease-in-out transform scale-100 translate-y-1/2 pointer-events-none select-none -bottom-6 group-hover:scale-110">
                <div
                  className="w-64 h-64 mx-auto transition-all duration-300 ease-in-out transform bg-slate-300 rounded-full group-hover:bg-slate-400"
                  style={{
                    transform: "rotateX(87deg)",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Error = connector(Container)
