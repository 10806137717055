import React from "react"
import Illustration from "../../../../../assets/lottie/loading.json"
import { connector, ContainerProps } from "./container"
import { Heading } from "../../../components/Heading/Heading"
import { FormattedMessage } from "../../../../../components/FormattedMessage/FormattedMessage"

export type Props = {}

export const Wrapper: React.FC<Props> = (props) => {
  return (
    <div className="relative flex w-full h-full p-4 overflow-hidden shadow-inner">
      <div className="w-full max-w-2xl mx-auto">
        <Heading
          title={<FormattedMessage id="login.auth-link.check.title" />}
          big
          description={
            <FormattedMessage id="login.auth-link.check.description" />
          }
          illustration={Illustration}
        ></Heading>
      </div>
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const Loading = connector(Container)
