import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../../redux/actions"
import { RootState } from "../../../../../redux/store"

const mapState = (state: RootState) => {
  const code = state.auth.flows.signinLinkValidation.form.email.state.code

  return {
    error: code !== "GOOD" && code !== "NOTHING",
    isFocus: state.auth.flows.signinLinkValidation.form.email.focus,
  }
}

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
